$.extend($.validator.messages, {
    required: "Это поле обязательное.",
    number: "Невалидное значение.",
    maxlength: $.validator.format("Максимум {0} символов."),
    minlength: $.validator.format("Минимум {0} символов.")
});

$("#consultForm").validate();

$('.navbar-toggle').on('click', function () {
    $('.tabs-overlay').addClass('-active');
    $('#tabsMenu').addClass('-active');
    $('body').addClass('-overflow');
});
$('.tabs-overlay').on('click', function () {
    $(this).removeClass('-active');
    $('#tabsMenu').removeClass('-active');
    $('body').removeClass('-overflow');
});
$('.section-tabs .tab-item a').on('click', function (e) {
    e.preventDefault();
    let this_href = $(this).attr('href');
    let clear_href = this_href.split('#')[1];
    let current_content = $('#'+clear_href);
    let header_height = $('.site-header').innerHeight();
    if(current_content.length < 1) return;
    $('.tabs-overlay').removeClass('-active');
    $('#tabsMenu').removeClass('-active');
    $('body').removeClass('-overflow');
    $('.section-tabs .tab-item').removeClass('-active');
    $('.section-tabs .tab-content').removeClass('-active');
    $(this).parent().addClass('-active');
    current_content.addClass('-active');
    if($(window).width() < 992) {
        $('html,body').animate({
            scrollTop: current_content.offset().top - header_height
        }, 1000);
    }
});

$(window).resize(function () {
    $('.tabs-overlay').removeClass('-active');
    $('#tabsMenu').removeClass('-active');
    $('body').removeClass('-overflow');
});